// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DashboardLayout {
    dashboardLayout {
      sections {
        columns {
          size
          blocks {
            size
            type
            id
            title: label
          }
        }
      }
    }
  }
`;

export default QUERY;
